import React from "react";
import Page from '../components/layout/Page';
import RTBG from '../images/Refining-Technique-bg.jpg';
import DownloadApp from '../components/index-page-sections/DownloadApp';

import ImgBgContainer from '../components/ImgBgContainer';

import './contact-us.scss';
import FeedbackForm from '../components/FeedbackForm/FeedbackForm';

const Contact = () => {
  return (
    <Page name="Contact Us" className="contact-us">
      <ImgBgContainer bgImg="/images/gold-bubbles-bg.jpg">
        <div className="container">
          <h2 className="pt-5 section-title gold-gradient-text">
            WE WOULD BE MORE THAN HAPPY<br/> TO SOLVE YOUR QUERIES.
          </h2>

          <div className="row mt-5">
            <div className="col-lg-6 p-5 pt-1 white-bg">
              <div className="contact-info text-light">
                <h3 className="my-3">Get in touch</h3>
                <div className="d-flex align-items-start my-3">
                  <span className="w-002 marg-001"><img src="../con1.png"/></span>
                  <div className="ms-3">
                    <span className="contact-item-title">Main Office:</span>
                    <p>
                      416, Fourth Floor, World Trade Center, Babar Road, New
                      Delhi - 110001
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start my-3">
                <span className="w-002 marg-001"><img src="../con2.png"/></span>
                  <div className="ms-3">
                    <span className="contact-item-title">Phone:</span>
                    <p>
                      <a href="tel://01143093345">01143093345</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start my-3">
                <span className="w-002 marg-001"><img src="../con3.png"/></span>
                  <div className="ms-3">
                    <span className="contact-item-title" text-dark>
                      Email:
                    </span>
                    <p>
                      <a href="Connect@brightgold.com">
                        Connect@brightgold.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-light p-5 pt-0">
              <h3 className="my-3 title">Contact us</h3>
              <form
                method="POST"
                id="contactForm"
                name="contactForm"
                className="contact-form"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control bg-transparent border-gold text-dark"
                        name="name"
                        id="name"
                        placeholder="Your name"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control bg-transparent border-gold text-dark"
                        name="city"
                        id="city"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control bg-transparent border-gold text-dark"
                        name="email"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control bg-transparent border-gold text-dark"
                        name="mobile"
                        id="mobile"
                        placeholder="Mobile number"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control bg-transparent border-gold text-dark"
                        id="message"
                        cols={30}
                        rows={5}
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-primary btn-submit my-3"
                      />
                      <div className="submitting"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ImgBgContainer>
      <DownloadApp />
    </Page>
  );
};

export default Contact;
