import React, { useEffect } from 'react';
import logo from '../../../images/bdg-logo.svg';

import { Link } from 'gatsby';

import './footer.scss';
import '../../../css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer blue-bg footer-distributed py-4 top-separator">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src="/images/bdg-logo-icon.png" alt="" className="logo" />

            <div className="my-3">
              <h6>ABOUT THE COMPANY</h6>
              <p className="about-text">
                Bright Metal Refiners was established with an apparition to
                approach out as the leading refinery for the majority of
                precious metals in the globe.
              </p>
            </div>

            <div className="footer-icons my-3">
              <a href="#">
                <i className="social-icon fa-brands fa-facebook"></i>
              </a>
              <a href="#">
                <i className="social-icon fa-brands fa-twitter"></i>
              </a>
              <a href="#">
                <i className="social-icon fa-brands fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="social-icon fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>

          <div className="col-lg-3 footer-links">
            <a href="/">Home</a>

            <Link to="/products/">Products</Link>

            <Link to="/technology/">Technology</Link>

            <Link to="/about-us/">About Us</Link>

            <Link to="/contact-us/">Contact Us</Link>
          </div>

          <div className="col-lg-3 contact-info">
            <h6 className="my-3">REACH US</h6>

            <div className="my-3">
              <i className="text-gold fa fa-phone"></i>
              <p className="m-0">01143093345</p>
            </div>
            <div className="my-6">
              <i className="text-gold fa fa-envelope"></i>
              <p className="m-0">connect@brightgold.com</p>
            </div>
            <div className="my-6">
              <i className="text-gold fa fa-map-marker"></i>
              <p className="m-0">
                416, 4th Floor, World Trade Center, Babar Road, New Delhi -
                110001
              </p>
            </div>
          </div>
        </div>

        <small className="copyright d-block my-3">
          © Copyright 2022 Bright Metal Refiners
        </small>
      </div>
    </footer>
  );
};

export default Footer;
