import React from 'react';

import './download-app.scss';

const DownloadApp = () => {
  return (
    <section className="download-app container py-5">
      <h2 className="section-title">Download Our App</h2>
      <p className="text-center fw-bold">for better experience</p>

      <div className="row my-5">
        <div className="col-md-6 d-flex justify-content-center">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.chirayusoft.brightgoldlive"
            className="text-decoration-none"
          >
            <button className="btn-download">
              <img src="/images/icons/google-play.png" width="50" />
              <div>
                <span className="title">Available on the</span>
                <span className="d-block name">Google Play</span>
              </div>
            </button>
          </a>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <a
            target="_blank"
            href="https://apps.apple.com/in/app/bright-gold/id1577786322"
            className="text-decoration-none"
          >
            <button className="btn-download">
              <img src="/images/icons/app-store.png" width="50" />
              <div>
                <span className="title">Download on the</span>
                <span className="d-block name">App Store</span>
              </div>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
