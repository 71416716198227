import React from 'react';

import './links-overlay.scss';

const LinksOverlay = () => {
  return (
    <div className="links-overlay">
      <nav className="nav-container">
        <ul className="links-list p-0 m-0">
          <li className="nav-link mx-5" style={{ '--animation-order': 1 }}>
            <a href="/products">Products</a>
          </li>
          <li className="nav-link mx-5" style={{ '--animation-order': 2 }}>
            <a href="/technology">Technology</a>
          </li>
          <li className="nav-link mx-5" style={{ '--animation-order': 3 }}>
            <a href="/about-us">About Us</a>
          </li>
          <li className="nav-link mx-5" style={{ '--animation-order': 4 }}>
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default LinksOverlay;
