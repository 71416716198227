import React from 'react';

import './img-bg-container.scss';

const ImgBgContainer = (props) => {
  const { bgImg, overlay, className, overlayProps = {} } = props;
  return (
    <section
      className={`img-bg-container ${className || ''}`}
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className={`overlay-${overlay || ''} ${overlayProps.className}`}>
        {props.children}
      </div>
    </section>
  );
};

export default ImgBgContainer;
