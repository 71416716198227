import React, { useEffect, useState } from 'react';

import LinksOverlay from '../../LinksOverlay';

import './header.scss';

import { menuLinks } from './header.json';

const Header = (props) => {
  const { activePage } = props;

  const [showLinksOverlay, setShowLinksOverlay] = useState(false);

  const toggleLinksOverlay = () => {
    setShowLinksOverlay(!showLinksOverlay);
  };

  useEffect(() => {
    showLinksOverlay
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [showLinksOverlay]);

  return (
    <header className="header blue-bg">
      <div style={{ position: showLinksOverlay ? 'sticky' : 'static', top: 0 }}>
        <nav className="navbar">
          <div className="container d-flex">
            <a className="navbar-brand" href="/">
              <img
                src="/images/bdg-logo.png"
                alt="logo"
                className="d-inline-block align-text-top logo"
              />
            </a>

            <div className="d-block d-lg-none">
              <button
                onClick={toggleLinksOverlay}
                className="btn-toggle-links"
                style={{
                  transform: showLinksOverlay ? 'rotate(90deg)' : 'rotate(0)',
                }}
              >
                <i className={showLinksOverlay ? 'bi bi-x' : 'bi bi-list'} />
              </button>
            </div>
            <div className="navbar-nav d-none d-lg-block">
              <ul className="nav-list p-0 m-0">
                {menuLinks.map((link) => (
                  <li
                    className={`nav-link px-1 mx-4 ${
                      link.label === activePage ? 'active' : ''
                    }`}
                  >
                    <a href={link.url}>{link.label}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {showLinksOverlay && <LinksOverlay />}
    </header>
  );
};

export default Header;
