import React from 'react';

import Header from './Header';
import Footer from './Footer';

const Page = (props) => {
  const { name } = props;

  return (
    <div className={props.className}>
      <Header activePage={name} />
      {props.children}
      <Footer />
    </div>
  );
};
export default Page;
